import Logo from "../assets/olion-logo.svg";
import {createEffect} from "solid-js";
import AOS from "aos";
import {A} from "@solidjs/router";

export default function Footer() {
    createEffect(() => {
        // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    })
    return (
        <footer>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">

                 {/* Blocks*/}
                <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12">

                     {/* 1st block*/}
                    <div className="sm:col-span-12 lg:col-span-4 order-1 lg:order-none">
                        <div className="h-full flex flex-col sm:flex-row lg:flex-col justify-between">
                            <div className="mb-4 sm:mb-0">
                                <div className="mb-4">
                                     {/* Logo*/}
                                    <a className="block" href="#home" aria-label="Olion Logo">
                                        <img src={Logo} width="38" height="38" alt="Olion"/>
                                    </a>
                                </div>
                                <div className="text-sm text-slate-300">&copy; Olion.com <span
                                    className="text-slate-500">-</span> All rights reserved
                                </div>
                            </div>
                             {/* Social links*/}
                            <ul className="flex">
                                <li>
                                    <a className="flex justify-center items-center text-gold-500 hover:text-gold-400 transition duration-150 ease-in-out"
                                       href="#0" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current p-1 mr-2" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M12.001 2c-5.523 0-10 4.477-10 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89c1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12c0-5.523-4.477-10-10-10Z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a className="flex justify-center items-center text-gold-500 hover:text-gold-400 transition duration-150 ease-in-out"
                                       href="#0" aria-label="Twitter">
                                        <svg className="w-8 h-8 fill-current p-1" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M22.212 5.656a8.384 8.384 0 0 1-2.401.658A4.195 4.195 0 0 0 21.649 4c-.82.488-1.719.83-2.655 1.015a4.182 4.182 0 0 0-7.126 3.814a11.874 11.874 0 0 1-8.621-4.37a4.168 4.168 0 0 0-.566 2.103c0 1.45.739 2.731 1.86 3.481a4.169 4.169 0 0 1-1.894-.523v.051a4.185 4.185 0 0 0 3.355 4.102a4.205 4.205 0 0 1-1.89.072A4.185 4.185 0 0 0 8.02 16.65a8.394 8.394 0 0 1-6.192 1.732a11.831 11.831 0 0 0 6.41 1.88c7.694 0 11.9-6.373 11.9-11.9c0-.18-.004-.362-.012-.541a8.497 8.497 0 0 0 2.086-2.164Z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="ml-2">
                                    <a className="flex justify-center items-center text-gold-500 hover:text-gold-400 transition duration-150 ease-in-out"
                                       href="#0" aria-label="Instagram">
                                        <svg className="w-8 h-8 fill-current p-1" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M13.028 2.001a78.82 78.82 0 0 1 2.189.022l.194.007c.224.008.445.018.712.03c1.064.05 1.79.218 2.427.465c.66.254 1.216.598 1.772 1.154a4.908 4.908 0 0 1 1.153 1.771c.247.637.415 1.364.465 2.428c.012.266.022.488.03.712l.006.194a79 79 0 0 1 .023 2.188l.001.746v1.31a78.836 78.836 0 0 1-.023 2.189l-.006.194c-.008.224-.018.445-.03.712c-.05 1.064-.22 1.79-.466 2.427a4.884 4.884 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465c-.267.012-.488.022-.712.03l-.194.006a79 79 0 0 1-2.189.023l-.746.001h-1.309a78.836 78.836 0 0 1-2.189-.023l-.194-.006a60.64 60.64 0 0 1-.712-.03c-1.064-.05-1.79-.22-2.428-.466a4.89 4.89 0 0 1-1.771-1.153a4.904 4.904 0 0 1-1.154-1.772c-.247-.637-.415-1.363-.465-2.427a74.367 74.367 0 0 1-.03-.712l-.005-.194A79.053 79.053 0 0 1 2 13.028v-2.056a78.82 78.82 0 0 1 .022-2.188l.007-.194c.008-.224.018-.446.03-.712c.05-1.065.218-1.79.465-2.428A4.88 4.88 0 0 1 3.68 3.68a4.897 4.897 0 0 1 1.77-1.155c.638-.247 1.363-.415 2.428-.465l.712-.03l.194-.005A79.053 79.053 0 0 1 10.972 2h2.056Zm-1.028 5A5 5 0 1 0 12 17a5 5 0 0 0 0-10Zm0 2A3 3 0 1 1 12.001 15a3 3 0 0 1 0-6Zm5.25-3.5a1.25 1.25 0 0 0 0 2.498a1.25 1.25 0 0 0 0-2.5Z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="ml-2">
                                    <a className="flex justify-center items-center text-gold-500 hover:text-gold-400 transition duration-150 ease-in-out"
                                       href="#0" aria-label="LinkedIn">
                                        <svg className="w-8 h-8 fill-current p-1" viewBox="0 0 24 24">
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M18.336 18.339h-2.665v-4.177c0-.996-.02-2.278-1.39-2.278c-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387c2.7 0 3.2 1.778 3.2 4.091v4.715ZM7.004 8.575a1.546 1.546 0 0 1-1.548-1.549a1.548 1.548 0 1 1 1.547 1.549Zm1.336 9.764H5.667V9.75H8.34v8.589ZM19.67 3H4.33C3.594 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.339C20.4 21 21 20.42 21 19.703V4.297C21 3.581 20.4 3 19.666 3h.003Z"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                     {/* 2nd block*/}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-50 font-medium mb-2">Platform</h6>
                        <ul className="text-sm space-y-2">
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="#features">Features</a>
                            </li>
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="#e-commerce">E-commerce</a>
                            </li>
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="#feeds">Feeds</a>
                            </li>
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="#b2b-marketplace">B2B Marketplace</a>
                            </li>
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="#portfolio">Portfolio</a>
                            </li>
                        </ul>
                    </div>

                     {/* 3rd block*/}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-50 font-medium mb-2">Company</h6>
                        <ul className="text-sm space-y-2">
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">About us</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Diversity & Inclusion</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Blog</a>*/}
                            {/*</li>*/}
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="mailto:careers@olion.com">Careers</a>
                            </li>
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Financial statements</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>

                     {/* 4th block*/}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-50 font-medium mb-2">Resources</h6>
                        <ul className="text-sm space-y-2">
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Community</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Terms of service</a>*/}
                            {/*</li>*/}
                            <li>
                                <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="mailto:contact@olion.com">Report a vulnerability</a>
                            </li>
                        </ul>
                    </div>

                     {/* 5th block*/}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-50 font-medium mb-2">Legals</h6>
                        <ul className="text-sm space-y-2">
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Refund policy</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Terms & Conditions</a>*/}
                            {/*</li>*/}
                            <li>
                                <A className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"
                                   href="/privacy-policy">Privacy policy</A>
                            </li>
                            {/*<li>*/}
                            {/*    <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out"*/}
                            {/*       href="#0">Brand Kit</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>

                </div>

            </div>
        </footer>
    )
}
