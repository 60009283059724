import { Modal } from 'flowbite';
import {createEffect, createSignal} from "solid-js";
import Logo from "../../assets/olion-logo.svg";
import Illustration from "../../assets/images/auth-illustration.svg";
import {waitlistNumber} from "../../pages/Home";
import {joinWaitlistModalOpen, setJoinWaitlistModalOpen} from "./JoinWaitlistModal";
import {setScrollPopupOpen} from "./ScrollPopup";

export const [leaveSiteModalOpen, setLeaveSiteModalOpen] = createSignal(false);

export default function LeaveSitePopup() {
    let modalRef;
    const [modal, setModal] = createSignal(null);
    const [alreadyShown, setAlreadyShown] = createSignal(false);

    createEffect(() => {
        setModal(new Modal(modalRef));
        //open the modal when user leaves the page with the cursor
        window.document.body.addEventListener('mouseleave', (event) => {
            //check if the cursor is on the right side of the screen (avoid scrollbar event fire)
            if(window.document.body.clientWidth - event.x > 10 && !alreadyShown() && !joinWaitlistModalOpen()) {
                setLeaveSiteModalOpen(true);
                setAlreadyShown(true);
                setScrollPopupOpen(false);
            }
        });
    })

    createEffect(() => {
        if(modal()) leaveSiteModalOpen() ? modal().show() : modal().hide();
    })

    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && leaveSiteModalOpen()) {
            setLeaveSiteModalOpen(false);
        }
    });

    return (
        <div ref={modalRef} onClick={() => setLeaveSiteModalOpen(false)} tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div class="relative w-full max-w-xl max-h-full" onClick={(e) => e.stopPropagation()}>
                {/* Modal content */}
                <div class="relative font-inter antialiased bg-slate-950 text-slate-200 tracking-tight border border-transparent rounded-2xl shadow-2xl [background:linear-gradient(theme(colors.slate.950),_theme(colors.slate.950))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl">
                    <button onClick={() => setLeaveSiteModalOpen(!leaveSiteModalOpen())} type="button" class="z-10 absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>

                    <section className="relative p-4 sm:p-10">
                        {/* Illustration */}
                        <div className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none -z-10" aria-hidden="true">
                            <img src={Illustration} className="max-w-none" width="1440" height="450" alt="Page Illustration"/>
                        </div>

                        <div className="relative">
                            <div className="pt-4 pb-4 md:pt-6 md:pb-4">

                                {/* Page header */}
                                <div className="max-w-3xl mx-auto text-center">
                                    {/* Logo */}
                                    <div className="mb-5">
                                        <div className="inline-flex">
                                            <div className="relative flex items-center justify-center w-28 h-28">
                                                <img className="relative" src={Logo} alt="Olion"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Page title */}
                                    <h1 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">Before you leave...</h1>
                                    <div className="flex justify-center mt-6 mb-4">
                                        <a onClick={() => setJoinWaitlistModalOpen(true) && setLeaveSiteModalOpen(false)} className="btn cursor-pointer text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white transition duration-150 ease-in-out group" href="#0">
                                            Join the Waitlist
                                            <span className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                        </a>
                                    </div>
                                    <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pt-3">
                                        <b>{waitlistNumber()}</b>&nbsp;businesses have already signed up
                                    </div>
                                    <h5 className="h5 bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-200 pt-4">
                                        Early adopters will get access to exclusive <b>deals</b> such as <b>free hosting</b> for the first three months
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
