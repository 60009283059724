import 'flowbite';
import {Route, Router, Routes} from "@solidjs/router";
import Home from "./pages/Home";
import {lazy} from "solid-js";

const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

export const isProduction = !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export default function App() {
    return (
        <Routes>
            <Route path="/" component={Home}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
        </Routes>
    );
}
