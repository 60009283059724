import {createEffect, createSignal, For} from "solid-js";
import AOS from "aos";
import GlowTop from '../assets/images/glow-top.svg';
import FeaturesEcommerce from '../assets/images/features-ecommerce.jpg';
import FeaturesFeeds from '../assets/images/features-feeds.jpg';
import FeaturesB2B from '../assets/images/features-b2b.jpg';

export default function FeaturesApplication() {
    const tabs = [
        {
            title: 'E-Commerce',
            description: 'Everything that you will ever need to run a successful business including<br/>' +
                '<ul class="list-disc ml-5 flex flex-col gap-y-2 mt-4 text-start">' +
                '   <li class="font-bold">AI Tools</li>' +
                '   <li class="font-bold">Order Management</li>' +
                '   <li class="font-bold">Product Catalog</li>' +
                '   <li class="font-bold">CRM</li>' +
                '   <li class="font-bold">Analytics, Reports & Insights</li>' +
                '   <li class="font-bold">Integrated headless CMS</li>' +
                '   <li class="font-bold">Marketing Tools</li>' +
                '   <li>and much more...</li>' +
                '</ul>',
                // '<br/>Then on top of that also gain access to...',// +
                // '</br>' +
                // '<ul class="list-disc ml-5 flex flex-col gap-y-2 mt-2">' +
                //     '<li>Manage your whole Store`s online presence, from Products and Diamonds to Orders, Customers, Inventory...</li>' +
                //     '<li>Access to our Product & Diamond Feeds available for you to sell as your own.</li>' +
                //     '<li>Exclusive B2B Marketplace where you will find even more items to sell from other Jewelers using our platform.</li>' +
                // '</ul>',
            // button: {
            //     text: 'Join the Waitlist',
            //     url: '#',
            //     icon: true
            // },
            imageSrc: FeaturesEcommerce
        },
        {
            title: 'Jewelry & Diamond Feeds',
            description: 'Craft Your Vision & Unleash creativity with our comprehensive catalog of <b>curated Jewelry & Diamond feeds</b>, ready for you to put on you site as your own and captivate your customers',
            // button: {
            //     text: 'Sign up for Free',
            //     url: 'https://diamonds.olion.app',
            //     target: '_blank',
            //     icon: true
            // },
            imageSrc: FeaturesFeeds
        },
        {
            title: 'B2B Marketplace',
            description: 'Expand Horizons & Amplify your reach by selling your products on Olion\'s B2B Marketplace, connecting you with other websites using our platform to multiply your sales avenues',
            // button: {
            //     text: 'Coming Soon',
            //     url: '#'
            // },
            imageSrc: FeaturesB2B
        },
        // {
        //     title: 'Olion Sparkle',
        //     description: '3D Jewelry <b>Viewer & Editor</b> for your Store. Create variations of your jewelry in 3D then display them however you see fit on your Store.',
        //     // button: {
        //     //     text: 'Coming Soon',
        //     //     url: '#'
        //     // },
        //     imageSrc: 'https://picsum.photos/id/4/2174/1464'
        // }
    ]
    const [selectedTab, setSelectedTab] = createSignal(0);
    let canvasRef;
    let highlighterRef;
    createEffect(() => {
        const options = {
            quantity: canvasRef.dataset.particleQuantity,
            staticity: canvasRef.dataset.particleStaticity,
            ease: canvasRef.dataset.particleEase,
        };
        new ParticleAnimation(canvasRef, options);
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
        new Highlighter(highlighterRef);
    })

    return (
        <section id="features" aria-label="Features for running your books"
                 className="relative overflow-hidden bg-blue-600 pb-28 pt-20 sm:py-32">

            {/* Illustration */}
            <div class="absolute inset-0 -z-10 -mx-28 rounded-t-[3rem] pointer-events-none overflow-hidden"
                 aria-hidden="true">
                <div class="absolute left-1/2 -translate-x-1/2 top-0 -z-10">
                    <img src={GlowTop} class="max-w-none" width="1404" height="658"
                         alt="Features Illustration"/>
                </div>
            </div>

            <div className="absolute inset-0 -z-10" aria-hidden="true">
                <canvas ref={canvasRef}></canvas>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative" data-aos="fade-down">
                <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
                    <h2 id="features" className="inline-flex text-3xl sm:text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                        Elevate Your Jewelry Business with our Innovative Solutions
                    </h2>
                    <p className="mt-6 text-lg tracking-tight text-slate-400">
                        Discover how Olion empowers your brand with a personalized AI jewelry<br/>e-commerce website and cutting-edge tools for success
                    </p>
                </div>
                <div
                    className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">
                    <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                        <div ref={highlighterRef}
                            className="relative group z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal"
                            role="tablist" aria-orientation="vertical">
                            <For each={tabs}>
                                {(tab, index) => (
                                    <div onClick={() => setSelectedTab(index)}
                                        className={`relative lg:rounded-l-xl lg:rounded-r-none border-slate-800 mr-[-2px] ${selectedTab() === index()  ? 'bg-slate-600' : ''} rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden`}>
                                        <div
                                            className="relative h-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6  bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                            <h3>
                                                <button
                                                    className=" font-display text-lg [&amp;:not(:focus-visible)]:focus:outline-none text-blue-600 lg:text-white"
                                                    id={`features-tab-${index()}`} role="tab" type="button" aria-selected="true"
                                                    tabIndex="0" data-headlessui-state="selected"
                                                    aria-controls="headlessui-tabs-panel-:Rda76:">
                                                    <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>
                                                    <h3 className="inline-flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                                                        {tab.title}
                                                    </h3>
                                                </button>
                                            </h3>
                                            <p className="mt-2 hidden text-sm lg:block text-slate-400" innerHTML={tab.description}/>
                                            {tab.button &&
                                                <div class="justify-end hidden lg:flex">
                                                    <a target={tab.button.target} className="btn w-auto text-slate-200 hover:text-white bg-slate-700 bg-opacity-25 hover:bg-opacity-30 w-full transition duration-150 ease-in-out"
                                                       href={tab.button.url}>
                                                        {tab.button.icon && (
                                                            <span>{tab.button.text}</span>
                                                        )}
                                                        {!tab.button.icon && (
                                                            <span className="text-slate-500">{tab.button.text}</span>
                                                        )}
                                                        {tab.button.icon && (
                                                            <span className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                                        )}
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </For>
                        </div>
                    </div>
                    <div className="lg:col-span-7">
                        <For each={tabs}>
                            {(tab, index) => (
                                <div role="tabpanel" tabIndex={index()} aria-labelledby="headlessui-tabs-tab-:R2ba76:" className={`lg:z-10 lg:relative ${index() !== selectedTab() ? 'hidden' : ''}`}>
                                    <div className="relative sm:px-6 lg:hidden">
                                        <div
                                            className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl pointer-events-none"></div>
                                        <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center mb-6"
                                           innerHTML={tab.description}/>
                                        {tab.button &&
                                            <div className="justify-center flex lg:hidden">
                                                <a target={tab.button.target}
                                                   className="btn w-auto text-slate-200 hover:text-white bg-slate-700 bg-opacity-25 hover:bg-opacity-30 w-full transition duration-150 ease-in-out"
                                                   href={tab.button.url}>
                                                    {tab.button.icon && (
                                                        <span>{tab.button.text}</span>
                                                    )}
                                                    {!tab.button.icon && (
                                                        <span className="text-slate-500">{tab.button.text}</span>
                                                    )}
                                                    {tab.button.icon && (
                                                        <span
                                                            className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                                    )}
                                                </a>
                                            </div>
                                        }
                                    </div>
                                    <div
                                        className="mt-10 w-auto overflow-hidden rounded-xl relative shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[72rem]">
                                        <img  alt="" fetchpriority="high" width="2174" height="1464" decoding="async" className="w-full" style="color:transparent"
                                             src={tab.imageSrc}/>
                                    </div>
                                </div>
                            )}
                        </For>
                    </div>
                </div>
            </div>
        </section>
    )
}
