import Client01 from '../assets/images/client-01.svg'
import Client02 from '../assets/images/client-02.svg'
import Client03 from '../assets/images/client-03.svg'
import Client04 from '../assets/images/client-04.svg'
import Client05 from '../assets/images/client-05.svg'
import Client06 from '../assets/images/client-06.svg'
import Client07 from '../assets/images/client-07.svg'
import Client08 from '../assets/images/client-08.svg'
import Client09 from '../assets/images/client-09.svg'
import {createEffect} from "solid-js";
import AOS from "aos";

export default function Clients() {
    let clientsEl;
    createEffect(() => {
        if (clientsEl) {
            const clients = new Swiper(clientsEl, {
                slidesPerView: 'auto',
                spaceBetween: 64,
                centeredSlides: true,
                loop: true,
                speed: 5000,
                noSwiping: true,
                noSwipingClass: 'swiper-slide',
                autoplay: {
                    delay: 0,
                    disableOnInteraction: true,
                },
            });
        }
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    })

    return (
        <section>
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">

                 {/* Particles animation*/}
                <div className="absolute inset-0 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="absolute inset-0 -z-10" aria-hidden="true">
                        <canvas data-particle-animation data-particle-quantity="5"></canvas>
                    </div>
                </div>

                <div className="py-12 md:py-16">
                    <div className="overflow-hidden">
                         {/* Carousel built with Swiper.js [https://swiperjs.com/]*/}
                         {/* * Initialized in src/js/main.js*/}
                         {/* * Custom styles in src/css/additional-styles/theme.scss*/}
                        <div ref={clientsEl}
                            className="clients-carousel swiper-container relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-slate-900 after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-slate-900">
                            <div className="swiper-wrapper !ease-linear select-none items-center whitespace-nowrap">
                                 {/* Carousel items*/}
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    AI Driven
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-slate-500 pb-3">
                                    |
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    CAD & Casting
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-slate-500 pb-3">
                                    |
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    Industry Insights
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-slate-500 pb-3">
                                    |
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    B2B Marketplace
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-slate-500 pb-3">
                                    |
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    Customization Tools
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-slate-500 pb-3">
                                    |
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    24/7 Support
                                </h3>
                                <h3 className="swiper-slide w-fit h3 bg-clip-text text-transparent bg-slate-500 pb-3">
                                    |
                                </h3>
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client01} alt="Client 01" width="110" height="21"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client02} alt="Client 02" width="70" height="25"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img className="mt-1" src={Client03} alt="Client 03" width="107"*/}
                                {/*         height="33"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client04} alt="Client 04" width="85" height="36"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client05} alt="Client 05" width="86" height="18"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client06} alt="Client 06" width="78" height="34"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client07} alt="Client 07" width="83" height="23"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img src={Client08} alt="Client 08" width="98" height="26"/>*/}
                                {/*</div>*/}
                                {/*<div className="swiper-slide !w-auto">*/}
                                {/*    <img className="mt-2" src={Client09} alt="Client 09" width="92"*/}
                                {/*         height="28"/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
