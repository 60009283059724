import {createEffect} from "solid-js";

import CarouselImg01 from '../assets/images/carousel-icon-01.svg'
import CarouselImg02 from '../assets/images/carousel-icon-02.svg'
import CarouselImg03 from '../assets/images/carousel-icon-03.svg'
import CarouselImg04 from '../assets/images/carousel-icon-04.svg'
import CarouselImg05 from '../assets/images/carousel-icon-05.svg'
import AOS from "aos";

export default function FeedsFeatures() {
    let testimonialsCarouselRef;
    let highlighterRef;
    let canvasRef;
    let canvasRef1;
    let canvasRef2;
    let canvasRef3;
    createEffect(() => {
        new Swiper(testimonialsCarouselRef, {
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                }
            },
            grabCursor: true,
            loop: false,
            centeredSlides: false,
            initialSlide: 0,
            spaceBetween: 24,
            navigation: {
                nextEl: '.carousel-next',
                prevEl: '.carousel-prev',
            },
        });

        [canvasRef, canvasRef1, canvasRef2, canvasRef3].forEach((_canvasRef) => {
            const options = {
                quantity: _canvasRef.dataset.particleQuantity,
                staticity: _canvasRef.dataset.particleStaticity,
                ease: _canvasRef.dataset.particleEase,
            };
            new ParticleAnimation(_canvasRef, options);
        });
        new Highlighter(highlighterRef);
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    });
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-12 md:pt-20">
                     {/* Section header*/}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <div>
                            <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-200 pb-3">
                                cherry-picked & curated
                            </div>
                        </div>
                        <h2 id="feeds" className="h2 font-normal bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
                            Jewelry & Diamond <b>Feeds</b>
                        </h2>
                        <p className="text-lg text-slate-400">
                            Get access to our wide range of jewelry and diamond feeds that you can sell on your website. Sell them as your own, keep the profit
                        </p>
                    </div>

                     {/* * Custom styles in src/css/additional-styles/theme.scss*/}
                    <div
                        className="relative before:absolute before:inset-0 before:-translate-x-full before:z-20 before:bg-gradient-to-l before:from-transparent before:to-slate-900 before:to-20% after:absolute after:inset-0 after:translate-x-full after:z-20 after:bg-gradient-to-r after:from-transparent after:to-slate-900 after:to-20%">
                        <div ref={testimonialsCarouselRef} className="testimonials-carousel swiper-container group">
                            <div className="swiper-wrapper w-fit" ref={highlighterRef}>
                                 {/* Carousel items*/}
                                <div className="swiper-slide h-auto relative bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden group/slide">
                                    <div className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                         {/* Particles animation*/}
                                        <div className="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out" aria-hidden="true">
                                            <canvas ref={canvasRef} data-particle-quantity="3"></canvas>
                                        </div>
                                         {/* Radial gradient*/}
                                        <div className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
                                            <div className="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-gold-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                                        </div>
                                        <div className="flex flex-col p-6 h-full">
                                            <img className="mb-3" src={CarouselImg01} width="56" height="56" alt="Icon 01"/>
                                            <div className="grow">
                                                <div className="font-bold text-white text-lg mb-1">Diamonds</div>
                                                <div className="text-slate-400 mb-3">
                                                    Natural, Lab-grown & Gemstones
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <div className="text-lg font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" href="#0">
                                                    <span className="text-sm text-slate-400 mt-0.5">more than&nbsp;&nbsp;</span> 100.000+ {/*<span className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="swiper-slide h-auto relative bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden group/slide">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                         {/* Particles animation*/}
                                        <div
                                            className="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out"
                                            aria-hidden="true">
                                            <canvas ref={canvasRef1} data-particle-quantity="3"></canvas>
                                        </div>
                                         {/* Radial gradient*/}
                                        <div
                                            className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square"
                                            aria-hidden="true">
                                            <div
                                                className="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-gold-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                                        </div>
                                        <div className="flex flex-col p-6 h-full">
                                            <img className="mb-3" src={CarouselImg02} width="56"
                                                 height="56" alt="Icon 01"/>
                                                <div className="grow">
                                                    <div className="font-bold text-white text-lg mb-1">Virtual Jewelry</div>
                                                    <div className="text-slate-400 mb-3">
                                                        Settings, Rings, Earrings, Pendants, Bracelets, Necklaces etc
                                                    </div>
                                                </div>
                                            <div className="text-right">
                                                <div className="text-lg font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" href="#0">
                                                    <span className="text-sm text-slate-400 mt-0.5">more than&nbsp;&nbsp;</span> 3.000+ {/*<span className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="swiper-slide h-auto relative bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden group/slide">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                         {/* Particles animation*/}
                                        <div
                                            className="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out"
                                            aria-hidden="true">
                                            <canvas ref={canvasRef2} data-particle-quantity="3"></canvas>
                                        </div>
                                         {/* Radial gradient*/}
                                        <div
                                            className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square"
                                            aria-hidden="true">
                                            <div
                                                className="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-gold-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                                        </div>
                                        <div className="flex flex-col p-6 h-full">
                                            <img className="mb-3" src={CarouselImg03} width="56"
                                                 height="56" alt="Icon 01"/>
                                                <div className="grow">
                                                    <div className="font-bold text-white text-lg mb-1">CAD & Casting Orders</div>
                                                    <div className="text-slate-400 mb-3">
                                                        Create and ship virtual jewelry with our CAD & Casting orders
                                                    </div>
                                                </div>
                                                <div className="text-right">
                                                    <a className="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group"
                                                       href="#0">Learn More <span
                                                        className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span></a>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="swiper-slide h-auto relative bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden group/slide">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                         {/* Particles animation*/}
                                        <div
                                            className="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out"
                                            aria-hidden="true">
                                            <canvas ref={canvasRef3} data-particle-quantity="3"></canvas>
                                        </div>
                                         {/* Radial gradient*/}
                                        <div
                                            className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square"
                                            aria-hidden="true">
                                            <div
                                                className="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-gold-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                                        </div>
                                        <div className="flex flex-col p-6 h-full">
                                            <img className="mb-3" src={CarouselImg04} width="56"
                                                 height="56" alt="Icon 01"/>
                                                <div className="grow">
                                                    <div className="font-bold text-white text-lg mb-1">Diamond Orders</div>
                                                    <div className="text-slate-400 mb-3">
                                                        Order the diamonds from our feeds and ship them to your customers
                                                    </div>
                                                </div>
                                                <div className="text-right">
                                                    <a className="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group"
                                                       href="#0">Learn More <span
                                                        className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span></a>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div*/}
                                {/*    className="swiper-slide h-auto relative bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden group/slide">*/}
                                {/*    <div*/}
                                {/*        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">*/}
                                {/*         /!* Particles animation*!/*/}
                                {/*        <div*/}
                                {/*            className="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out"*/}
                                {/*            aria-hidden="true">*/}
                                {/*            <canvas ref={canvasRef4} data-particle-quantity="3"></canvas>*/}
                                {/*        </div>*/}
                                {/*         /!* Radial gradient*!/*/}
                                {/*        <div*/}
                                {/*            className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square"*/}
                                {/*            aria-hidden="true">*/}
                                {/*            <div*/}
                                {/*                className="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-gold-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>*/}
                                {/*        </div>*/}
                                {/*        <div className="flex flex-col p-6 h-full">*/}
                                {/*            <img className="mb-3" src={CarouselImg05} width="56"*/}
                                {/*                 height="56" alt="Icon 05"/>*/}
                                {/*                <div className="grow">*/}
                                {/*                    <div className="font-bold text-white text-lg mb-1">Shipping</div>*/}
                                {/*                    <div className="text-slate-400 mb-3">*/}
                                {/*                        Create, cast & ship your virtual jewelry to your customers.*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*                <div className="text-right">*/}
                                {/*                    <a className="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group"*/}
                                {/*                       href="#0">Learn More <span*/}
                                {/*                        className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span></a>*/}
                                {/*                </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                     {/* Arrows*/}
                    <div className="flex mt-8 justify-end">
                        <button
                            className="carousel-prev relative z-20 w-12 h-12 flex items-center justify-center group">
                            <span className="sr-only">Previous</span>
                            <svg
                                className="w-4 h-4 fill-slate-500 group-hover:fill-gold-500 transition duration-150 ease-in-out"
                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z"/>
                            </svg>
                        </button>
                        <button
                            className="carousel-next relative z-20 w-12 h-12 flex items-center justify-center group">
                            <span className="sr-only">Next</span>
                            <svg
                                className="w-4 h-4 fill-slate-500 group-hover:fill-gold-500 transition duration-150 ease-in-out"
                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z"/>
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </section>
    )
}
