import {createEffect} from "solid-js";
import DPAScreenshot from '../assets/images/dpa-full-screenshot.png'
import AOS from "aos";

export default function DPAFeatures() {
    let highlighterRef;
    createEffect(() => {
        new Highlighter(highlighterRef);
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    })
    return (
        <section className="relative">

             {/* Blurred shape*/}
            <div
                className="absolute top-0 -translate-y-1/4 left-1/2 -translate-x-1/2 blur-2xl opacity-50 pointer-events-none -z-10"
                aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                    <defs>
                        <linearGradient id="bs3-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                            <stop offset="0%" stop-color="#ffbf00"/>
                            <stop offset="100%" stop-color="#ffbf00" stop-opacity="0"/>
                        </linearGradient>
                    </defs>
                    <path fill="url(#bs3-a)" fill-rule="evenodd" d="m410 0 461 369-284 58z"
                          transform="matrix(1 0 0 -1 -410 427)"/>
                </svg>
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6 pb-12 md:pb-20">
                <div className="pt-16 pb-12 md:pt-32 md:pb-20 border-b border-slate-800">

                     {/* Section header*/}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 id="portfolio" className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
                            Our Portfolio
                        </h2>
                        <p className="text-lg text-slate-400">
                            Discover other groundbreaking AI-based products from the Olion brand
                        </p>
                    </div>

                    <div className="max-w-3xl mx-auto">
                        <div data-aos="fade-down">
                            <div className="group" ref={highlighterRef}>
                                <div
                                    className="relative bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                         {/* Radial gradient*/}
                                        <div
                                            className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                            aria-hidden="true">
                                            <div
                                                className="absolute inset-0 translate-z-0 bg-gold-500 rounded-full blur-[120px]"></div>
                                        </div>
                                        <a href="https://olion.app" target="_blank">
                                            <img src={DPAScreenshot} width="768" height="400"
                                                 alt="Feature 04"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
