/* @refresh reload */
import { render } from 'solid-js/web';

import './index.css';
import App, {isProduction} from './App';
import { Router } from "@solidjs/router";

render(() => (
    // <Router base={isProduction ? '/portfolio' : ''}>
    <Router>
        <App />
    </Router>
), document.getElementById('root'));
