import AOS from 'aos';
import 'aos/dist/aos.css';
import {createEffect, createSignal} from "solid-js";
import Hero from "../components/Hero";
import Clients from "../components/Clients";
import StoreTypeFeatures from "../components/StoreTypeFeatures";
import B2BMarketplaceFeatures from "../components/B2BMarketplaceFeatures";
import DPAFeatures from "../components/DPAFeatures";
import EcommerceFeatures from "../components/EcommerceFeatures";
import FeedsFeatures from "../components/FeedsFeatures";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import FeaturesApplication from "../components/FeaturesApplication";
import JoinWaitlistModal from "../components/modals/JoinWaitlistModal";
import LeaveSitePopup from "../components/modals/LeaveSitePopup";
import ScrollPopup from "../components/modals/ScrollPopup";
import {isProduction} from "../App";

export const [waitlistNumber, setWaitlistNumber] = createSignal(23);

export default function Home() {

    createEffect(() => {
        AOS.init({
            once: true,
            disable: false,
            duration: 1000,
            easing: 'ease-out-cubic',
        })
        // fetch((isProduction ? '/portfolio' : '') + '/api/getWaitlistNumber')
        fetch('/api/getWaitlistNumber')
            .then((response) => response.json())
            .then((data) => {
                setWaitlistNumber(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    })

    return (
        <div>
            <main className="grow">
                <Hero />
                <Clients />
                <FeaturesApplication/>
                <StoreTypeFeatures/>
                <EcommerceFeatures/>
                <FeedsFeatures/>
                <B2BMarketplaceFeatures/>
                <DPAFeatures/>
                <CTA/>
                <Footer/>
                <JoinWaitlistModal/>
                <LeaveSitePopup/>
                <ScrollPopup/>
            </main>
        </div>
    );
}
